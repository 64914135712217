import React from "react";
import { useState } from "react";
import "./Navbar.css";

import Hamburger from "hamburger-react";

const Navbar = ({
  handleConsultationClick,
  handleAboutClick,
  handleContactsClick,
}) => {
  const [navLinksActive, setNavLinksActive] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const handleMenuButtonClick = () => {
    setNavLinksActive(!navLinksActive);
  };

  const handleLinkClick = () => {
    setNavLinksActive(false);
    setOpen(false);
  };

  return (
    <>
      <div className="navbar">
        <div className="menu-button" onClick={handleMenuButtonClick}>
          <Hamburger toggled={isOpen} toggle={setOpen} />
        </div>
        <div className="navbar-logo">Tylios Pievos</div>
        <div
          className={navLinksActive ? "navbar-links active" : "navbar-links"}
        >
          <div
            className={`navbar-link link`}
            onClick={() => {
              handleLinkClick();

              handleAboutClick();
            }}
          >
            Apie mane
          </div>
          <div
            className={`navbar-link link`}
            onClick={() => {
              handleLinkClick();

              handleConsultationClick();
            }}
          >
            Konsultacijos
          </div>
          <div
            className={`navbar-link link `}
            onClick={() => {
              handleLinkClick();

              handleContactsClick();
            }}
          >
            Kontaktai
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
