import React from "react";
import "./Consultation.css";

const Consultation = () => {
  return (
    <div className="container">
      <h1 className="page-title">Konsultacijos</h1>
      <div className="hr-line"></div>
      <div className="consultation-main">
        <p>
          Konsultacijų metu man yra svarbu išgirsti bei suprasti kliento
          patirtis, sunkumus, dilemas. Aktyviai dirbti kartu siekiant pokyčio.
          Stengiuosi žvelgti į situacijas iš daugelio taškų, dalintis įžvalgomis
          bei situacijos matymu, tačiau stebiu save, kad atsiribočiau nuo savo
          asmeninių patirčių, vertybių ar sprendimų, nes kiekvienas žmogus yra
          savo gyvenimo ekspertas. Būtent todėl pasirinkau į Sprendimus
          Orientuotos Trumpalaikės Terapijos kryptį.
        </p>

        <p>
          Konsultuoju suaugusiuosius, poras. Dažniausiai į mane kreipiasi dėl
          skyrybų patirčių, įtampų, kylančių porų santykiuose, nerimo, streso
          įveikos. Savo konsultavimo darbą reguliariai aptariu su į Sprendimus
          Sutelktos Trumpalaikės Terapijos krypties supervizoriumi.
        </p>
      </div>
    </div>
  );
};

export default Consultation;
