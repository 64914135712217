import "./App.css";
import { useRef } from "react";
import Navbar from "./components/Navbar";

import Contacts from "./components/Contacts";

import Apie from "./components/Apie";
import Footer from "./components/Footer";

import Consultation from "./components/Consultation";

function App() {
  const konsultacijosRef = useRef(null);
  const aboutRef = useRef(null);
  const contactsRef = useRef(null);

  const scrollToAbout = () => {
    aboutRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToContacts = () => {
    contactsRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToConsultation = () => {
    konsultacijosRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div className="app-container">
        <Navbar
          handleConsultationClick={scrollToConsultation}
          handleAboutClick={scrollToAbout}
          handleContactsClick={scrollToContacts}
        />

        <div ref={aboutRef}>
          <Apie />
        </div>

        <div ref={konsultacijosRef}>
          <Consultation />
        </div>

        <div ref={contactsRef}>
          <Contacts />
        </div>

        <Footer
          handleConsultationClick={scrollToConsultation}
          handleAboutClick={scrollToAbout}
          handleContactsClick={scrollToContacts}
        />
      </div>
    </>
  );
}

export default App;
