import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import "./Contacts.css";
import { FaPhoneAlt, FaEnvelope, FaClock } from "react-icons/fa";
import { IoMdPin } from "react-icons/io";

import { Button } from "@mantine/core";
import Map from "./Map";

export default function ContactUs() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    console.log(form.current);

    emailjs
      .sendForm(
        "service_6or2loc",
        "template_pgkvh4c",
        form.current,
        "8pVmJwcaaA2t_No5V"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <div className="container">
        <h1 className="page-title">Kontaktai</h1>
        <div className="hr-line"></div>
        <div className="contact-main-bottom">
          <div className="contact-main-left">
            <ul className="contact-items">
              <li className="contact-item">
                <FaPhoneAlt className="contact-icon" />
                <span className="contact-item-title">+370 618 40411</span>
              </li>

              <li className="contact-item">
                <FaEnvelope className="contact-icon" />
                <span className="contact-item-title">info@tyliospievos.lt</span>
              </li>

              <li className="contact-item">
                <IoMdPin className="contact-icon-small" />
                <span className="contact-item-title">Lukiškių g. 5</span>
              </li>
              {/* <li className="contact-item">
                <FaClock className="contact-icon" />
                <span className="contact-item-title">I-V 10:00-19:00</span>
              </li> */}
            </ul>
          </div>

          <div className="hr-line hr-line-responsive"></div>

          <div className="map">
            <Map />
          </div>
        </div>
      </div>
    </>
  );
}
