import React from "react";
import "./Footer.css";

const Footer = ({
  handleConsultationClick,
  handleAboutClick,
  handleContactsClick,
}) => {
  return (
    <div className="footer-container">
      <div className="footer-logo">Tylios Pievos</div>
      <div className="footer-links">
        <div className="footer-link link" onClick={handleAboutClick}>
          Apie mane
        </div>
        <div className="footer-link link" onClick={handleConsultationClick}>
          Konsultacijos
        </div>
        <div className="footer-link link" onClick={handleContactsClick}>
          Kontaktai
        </div>
      </div>
    </div>
  );
};

export default Footer;
