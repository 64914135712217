import React from "react";
import "./Apie.css";
import img from "../assets/photo.jpg";

const Apie = () => {
  return (
    <div className="container mtop">
      <h1 className="page-title">Apie mane</h1>
      <div className="hr-line"></div>

      <div className="about-me-main">
        <div className="about-me-info">
          <p>Esu Tomas Karpuška, psichologas.</p>

          <p>
            Artimą ryšį su psichologija užmezgiau dar 2009 metais, studijuodamas
            šią discipliną Vilniaus universitete bei savanoriaudamas „Jaunimo
            linijoje“. Nuo to laiko stengiuosi gilinti savo žinias per praktiką
            – dalyvaudamas seminaruose, mokymuose bei diskusijose.
          </p>

          <p>
            Savo profesinėje veikloje remiuosi į Sprendimus Sutelkta Trumpalaike
            Terapija. Šios terapijos esmė nusakoma gana paprastai: „Žmogus yra
            savo gyvenimo ekspertas. Tačiau kartais jis „suklumpa“ ir tuomet
            visas jį supantis pasaulis tarsi subyra. Ne visuomet pakanka vien
            tik artimojo rankos ar žodžio. Kartais būna visko per daug.“
            Terapinis procesas yra grindžiamas bendradarbiavimu su klientu, t.
            y. ieškant sprendimų konkrečiose situacijose ir pasirinkimuose.
          </p>

          <p>
            Daug dėmesio skiriu vyrų, išgyvenančių skyrybų procesus, emocijoms
            bei prieštaringų jausmų įveikoms. Neretai vyrų jausmai, ypač skaudūs
            ir skausmingi išgyvenimai, yra tarsi stigma. Visgi būtent dėl šios
            priežasties savo mokslinio darbo ašimi pasirinkau vyrų patirtis.
          </p>

          <p>
            Kartais sunkiausia yra praverti duris ir pasakyti: „Labas, man
            žiauriai skauda...“ Išdrįskite. Pakalbėkime.
          </p>
        </div>
        <div className="about-me-image">
          <img src={img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Apie;
