import React from "react";

const Map = () => {
  return (
    <>
      <iframe
        style={{
          width: "95%",
          height: "430px",
          border: "none",
        }}
        title="map"
        id="gmap_canvas"
        src="https://maps.google.com/maps?width=520&amp;height=402&amp;hl=en&amp;q=lukiskiu%20g%205%20Vilnius+(Map)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
      ></iframe>{" "}
    </>
  );
};

export default Map;
